@import url('https://fonts.googleapis.com/css2?family=Source+Sans+3:wght@400;600;700&display=swap');


html {
  width: 100%;
  height: 100%;
  font-family: 'Source Sans 3', sans-serif;
}

body,
#root,
.app {
  height: 100%;
  width: 100%;
  font-family: 'Source Sans 3', sans-serif;
}

.content {
  height: 100vh;
  max-height: 100vh;
  overflow-y: scroll;
  font-family: 'Source Sans 3', sans-serif;
}

.pl80 {
  padding-left: 80px;
}

.pt80 {
  padding-top: 80px;
}

.app {
  display: flex;
  position: relative;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background-color: #e0e0e0;
}

::-webkit-scrollbar-thumb {
  background-color: #888;
}

::-webkit-scrollbar-track:hover {
  background-color: #555;
}